import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useLessonsList() {
  // Use toast
  const toast = useToast();

  const refLessonsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name" },
    { key: "description" },
    { key: "exercises" },
    { key: "duration" },
    { key: "has-guide" },
    { key: "actions" },
  ];
  const per_page = ref(10);
  const totalLessons = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [10, 20, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  // Add lesson variables
  const addLessonModal = ref(false)
  const addLessonName = ref("")
  const addLessonDescription = ref("")
  const addLessonDuration = ref(1)
  const addTranslation = ref("no")
  const addLessonNameTranslation = ref("")
  const addLessonDescriptionTranslation = ref("")

  // Single lesson Data
  const lessonData = ref(null);

  // Edit lesson variables
  const editLessonModal = ref(false);
  const editLessonId = ref("");
  const editLessonName = ref("");
  const editLessonDescription = ref("")
  const editLessonDuration = ref(1)
  const editTranslation = ref("no")
  const editLessonNameTranslation = ref("");
  const editLessonDescriptionTranslation = ref("")

  // Add Lesson Guide
  const addLessonGuideModal = ref(false)
  const addLessonIdGuide = ref("")
  const addLessonGuide = ref("")
  const addGuideTranslation = ref("no")
  const addLessonGuideTranslation = ref("")

  // Edit Lesson Guide
  const editLessonGuideModal = ref(false)
  const editLessonGuideId = ref("")
  const editLessonGuide = ref("")
  const editGuideTranslation = ref("no")
  const editLessonGuideTranslation = ref("")

  const dataMeta = computed(() => {
    const localItemsCount = refLessonsListTable.value
      ? refLessonsListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalLessons.value,
    };
  });

  const refetchData = () => {
    refLessonsListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchLessons = (ctx, callback) => {
    store
      .dispatch("app-content/fetchLessons", {
        id: router.currentRoute.params.section_id,
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: lessons, total } = response.data.data;
        
        callback(lessons);
        totalLessons.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching lessons list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const storeLesson = (ctx, callback) => {
    const formData = new FormData();
    formData.append('section_id', router.currentRoute.params.section_id)
    formData.append("name", addLessonName.value);
    formData.append("description", addLessonDescription.value)
    formData.append("duration", addLessonDuration.value)
    formData.append("translation[name]", addLessonNameTranslation.value)
    formData.append("translation[description]", addLessonDescriptionTranslation.value)
    
    store
      .dispatch("app-content/addLesson", formData)
      .then(() => {
        refetchData();
        addLessonModal.value = false;
        addLessonName.value = "";
        addLessonDescription.value = "";
        addLessonDuration.value = 0
        addTranslation.value = false;
        addLessonNameTranslation.value = ""
        addLessonDescriptionTranslation.value = ""
        toast({
          component: ToastificationContent,
          props: {
            title: "Lesson Added.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the lesson.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateLesson = () => {
    const formData = new FormData();
    formData.append("name", editLessonName.value);
    formData.append("description", editLessonDescription.value)
    formData.append("duration", editLessonDuration.value)
    formData.append("translation[name]", editLessonNameTranslation.value)
    formData.append("translation[description]", editLessonDescriptionTranslation.value)

    store
      .dispatch("app-content/updateLesson", {
        id: editLessonId.value,
        data: formData,
      })
      .then((response) => {
        lessonData.value = response.data.data;
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: "Updated lesson.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        editLessonModal.value = false;
        editLessonId.value = "";
        editLessonName.value = "";
        editLessonDescription.value = "";
        editLessonNameTranslation.value = "";
        editLessonDescriptionTranslation.value = "";
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the lesson details.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const storeLessonGuide = (ctx, callback) => {
    const formData = new FormData
    formData.append('lesson_id', addLessonIdGuide.value)
    formData.append('guide', addLessonGuide.value)
    formData.append('translation[guide]', addLessonGuideTranslation.value)

    store.dispatch('app-content/addLessonGuide', formData)
      .then(() => {
        refetchData()
        addLessonGuideModal.value = false
        addLessonIdGuide.value = '',
        addLessonGuide.value = '',
        addGuideTranslation.value = 'no'
        addLessonGuideTranslation.value = ''
        toast({
          component: ToastificationContent,
          props: {
            title: "Lesson guide added successfully.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the lesson guide.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
  }

  const updateLessonGuide = (ctx, callback) => {
    const formData = new FormData
    formData.append('lesson_guide_id', editLessonGuideId.value)
    formData.append('guide', editLessonGuide.value)
    formData.append('translation[guide]', editLessonGuideTranslation.value)

    store.dispatch('app-content/updateLessonGuide', {id: editLessonGuideId.value, data: formData})
      .then(() => {
        refetchData()
        editLessonGuideModal.value = false
        editLessonGuideId.value = ''
        editLessonGuide.value = ''
        editGuideTranslation.value = 'no'
        editLessonGuideTranslation.value = ''
        toast({
          component: ToastificationContent,
          props: {
            title: "Lesson guide updated successfully.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the lesson guide.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
  }

  return {
    fetchLessons,
    storeLesson,
    updateLesson,
    tableColumns,
    per_page,
    current_page,
    totalLessons,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLessonsListTable,
    refetchData,

    addLessonModal,
    addLessonName,
    addLessonDescription,
    addLessonDuration,
    addTranslation,
    addLessonNameTranslation,
    addLessonDescriptionTranslation,

    lessonData,

    editLessonModal,
    editLessonId,
    editLessonName,
    editLessonDuration,
    editLessonDescription,
    editTranslation,
    editLessonNameTranslation,
    editLessonDescriptionTranslation,

    addLessonGuideModal,
    addLessonIdGuide,
    addLessonGuide,
    addGuideTranslation,
    addLessonGuideTranslation,

    editLessonGuideModal,
    editLessonGuideId,
    editLessonGuide,
    editGuideTranslation,
    editLessonGuideTranslation,

    storeLessonGuide,
    updateLessonGuide,
  };
}
