<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-1"
              variant="primary"
              @click="addLessonModal = true"
            >
              Add Lesson
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Add Lesson Modal -->
      <b-modal
        v-model="addLessonModal"
        hide-footer
        centered
        size="lg"
        title="Add Lesson"
      >
        <b-row>
          <b-col lg="8">
            <b-form-group label="Enter the Lesson Name" label-for="enterName">
              <b-form-input
                id="enterName"
                placeholder="Enter the Lesson Name"
                v-model="addLessonName"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <label for="demo-sb">Enter Lesson Duration(in minutes)</label>
            <b-form-spinbutton
              id="demo-sb"
              v-model="addLessonDuration"
              min="1"
              max="100"
            />
            <b-card-text class="mt-1">
              {{ addLessonDuration }} minutes
            </b-card-text>
          </b-col>
        </b-row>

        <b-form-group label="Lesson Description" label-for="lessonDescription">
          <b-form-textarea
            v-model="addLessonDescription"
            placeholder="Enter the lesson description"
            rows="2"
            :state="addLessonDescription.length <= maxChar"
            class="char-textarea"
            :class="addLessonDescription.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="addLessonDescription.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ addLessonDescription.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="addTranslation === 'yes'">
          <b-form-group label="Enter the Lesson Name Translation" label-for="enterNameTranslation">
            <b-form-input
              id="enterLessonTranslation"
              placeholder="Enter the Lesson Name Translation"
              v-model="addLessonNameTranslation"
            />
          </b-form-group>

          <b-form-group label="Lesson Description Translation" label-for="lessonDescriptionTranslation">
            <b-form-textarea
              v-model="addLessonDescriptionTranslation"
              placeholder="Enter the lesson description translation"
              rows="2"
              :state="addLessonDescriptionTranslation.length <= maxChar"
              class="char-textarea"
              :class="addLessonDescriptionTranslation.length >= maxChar ? 'text-danger' : ''"
            />
            <small
              class="textarea-counter-value float-right"
              :class="addLessonDescriptionTranslation.length >= maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ addLessonDescriptionTranslation.length }}</span> /
              {{ maxChar }}
            </small>
          </b-form-group>
        </div>

        <b-button variant="outline-primary" @click="storeLesson()">
          Submit
        </b-button>
      </b-modal>

      <!-- Edit Lesson Modal -->
      <b-modal
        v-model="editLessonModal"
        hide-footer
        centered
        size="lg"
        title="Edit Lesson"
      >
        <b-row>
          <b-col lg="8">
            <b-form-group label="Enter the Lesson Name" label-for="enterName">
              <b-form-input
                id="enterName"
                placeholder="Enter the Lesson Name"
                v-model="editLessonName"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <label for="demo-sb">Enter Lesson Duration(in minutes)</label>
            <b-form-spinbutton
              id="demo-sb"
              v-model="editLessonDuration"
              min="1"
              max="100"
            />
            <b-card-text class="mt-1">
              {{ editLessonDuration }} minutes
            </b-card-text>
          </b-col>
        </b-row>

        <b-form-group label="Lesson Description" label-for="lessonDescription">
          <b-form-textarea
            v-model="editLessonDescription"
            placeholder="Enter the lesson description"
            rows="2"
            :state="editLessonDescription.length <= maxChar"
            class="char-textarea"
            :class="editLessonDescription.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="editLessonDescription.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ editLessonDescription.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add/Edit Translation? </span>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="editTranslation === 'yes'">
          <b-form-group label="Enter/Edit the Lesson Name Translation" label-for="editNameTranslation">
            <b-form-input
              id="editNameTranslation"
              placeholder="Enter/Edit the Lesson Name Translation"
              v-model="editLessonNameTranslation"
            />
          </b-form-group>

          <b-form-group label="Enter/Edit Lesson Description Translation" label-for="lessonDescriptionTranslation">
            <b-form-textarea
              v-model="editLessonDescriptionTranslation"
              placeholder="Enter/Edit the lesson description translation"
              rows="2"
              :state="editLessonDescriptionTranslation.length <= maxChar"
              class="char-textarea"
              :class="editLessonDescriptionTranslation.length >= maxChar ? 'text-danger' : ''"
            />
            <small
              class="textarea-counter-value float-right"
              :class="editLessonDescriptionTranslation.length >= maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ editLessonDescriptionTranslation.length }}</span> /
              {{ maxChar }}
            </small>
          </b-form-group>
        </div>

        <b-button variant="outline-primary" @click="updateLesson">
          Submit
        </b-button>
      </b-modal>

      <!-- Add Lesson Guide Modal -->
      <b-modal
        v-model="addLessonGuideModal"
        hide-footer
        centered
        size="lg"
        title="Add Lesson Guide"
      >
        <b-form-group label="Lesson Guide" label-for="lessonGuide">
          <quill-editor
              v-model="addLessonGuide"
              :options="editorOption"
            />
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add/Edit Translation? </span>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="addTranslation === 'yes'">
          <b-form-group label="Lesson Guide Translation" label-for="lessonGuideTranslation">
            <quill-editor
              v-model="addLessonGuideTranslation"
              :options="editorOption"
            />
          </b-form-group>
        </div>

        <b-button variant="outline-primary" @click="storeLessonGuide()">
          Submit
        </b-button>
      </b-modal>

      <!-- Edit Lesson Guide Modal -->
      <b-modal
        v-model="editLessonGuideModal"
        hide-footer
        centered
        size="lg"
        title="View/Edit Lesson Guide"
      >
        <div class="demo-inline-spacing mb-1">
          <span>View Or Edit Guide? </span>
          <b-form-radio
            v-model="lessonGuideViewStatus"
            name="view-lesson"
            value="view"
            class="custom-control-primary"
          >
            View
          </b-form-radio>
          <b-form-radio
            v-model="lessonGuideViewStatus"
            name="edit-lesson"
            value="edit"
            class="custom-control-primary"
          >
            Edit
          </b-form-radio>
        </div>

        <div v-if="lessonGuideViewStatus === 'view'">
          <b-row>
            <b-col :cols="hasGuideTranslation ? '6' : '12'">
              <label for="Guide">Lesson Guide</label>
              <b-card v-html="editLessonGuide">
              </b-card>
            </b-col>
            <b-col cols="6" v-if="hasGuideTranslation">
              <label for="GuideTranslation">Lesson Guide Translation</label>
              <b-card v-html="editLessonGuideTranslation">
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-if="lessonGuideViewStatus === 'edit'">
          <b-form-group label="Lesson Guide" label-for="lessonGuide">
            <quill-editor
                v-model="editLessonGuide"
                :options="editorOption"
              />
          </b-form-group>
  
          <div class="demo-inline-spacing mb-1">
            <span>Add/Edit Translation? </span>
            <b-form-radio
              v-model="editGuideTranslation"
              name="some-radio9"
              value="yes"
              class="custom-control-primary"
            >
              Yes
            </b-form-radio>
            <b-form-radio
              v-model="editGuideTranslation"
              name="some-radio9"
              value="no"
              class="custom-control-primary"
            >
              No
            </b-form-radio>
          </div>
  
          <div v-if="editGuideTranslation === 'yes'">
            <b-form-group label="Lesson Guide Translation" label-for="lessonGuideTranslation">
              <quill-editor
                v-model="editLessonGuideTranslation"
                :options="editorOption"
              />
            </b-form-group>
          </div>
  
          <b-button variant="outline-primary" @click="updateLessonGuide()">
            Submit
          </b-button>
        </div>
      </b-modal>
      
      <b-table
        ref="refLessonsListTable"
        class="position-relative"
        :items="fetchLessons"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <!-- Column: Exercises -->
        <template #cell(description)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.description ? editDescriptionLength(data.item.description) : ''}}</span>
          </div>
        </template>

        <!-- Column: Exercises -->
        <template #cell(exercises)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.exercises.length }}</span>
          </div>
        </template>

        <!-- Column: Exercises -->
        <template #cell(duration)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.duration }} minutes</span>
          </div>
        </template>

        <!-- Column: Translation -->
        <template #cell(has-guide)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.guide.length ? 'Yes' : 'No' }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-info"
              :to="{ name: 'apps-exercises-list', params: { chapter_id: data.item.section.chapter.id, section_id: data.item.section.id, lesson_id: data.item.id } }"
            >
              View Exercises
            </b-button>
            <b-button
              class="mr-1"
              variant="outline-danger"
              size="sm"
              @click="editLessonDetails(data.item)"
            >
              Edit
            </b-button>
            <b-button
              v-if="data.item.guide.length"
              variant="outline-warning"
              size="sm"
              @click="getLessonGuide(data.item)"
            >
              View Guide
            </b-button>
            <b-button
              v-else
              variant="outline-warning"
              size="sm"
              @click="showAddLessonGuideModal(data.item)"
            >
              Add Guide
            </b-button>
          </div>
        </template>
      </b-table>
      
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalLessons"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
  BFormSpinbutton,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useLessonsList from "./useLessonsList";
import contentStore from "../contentStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,
    BFormSpinbutton,
    BFormRadio,

    vSelect,
    quillEditor,
  },
  setup() {
    const CONTENT_APP_STORE_MODULE_NAME = "app-content";

    const maxChar = ref(100);

    const editorOption = {
      theme: 'bubble',
    }

    // Show Lesson Guide
    const lessonGuideViewStatus = ref("view")
    const hasGuideTranslation = ref(false)

    // Register module
    if (!store.hasModule(CONTENT_APP_STORE_MODULE_NAME))
      store.registerModule(CONTENT_APP_STORE_MODULE_NAME, contentStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CONTENT_APP_STORE_MODULE_NAME);
    });

    const showAddLessonGuideModal = (item) => {
      addLessonIdGuide.value = item.id
      addLessonGuideModal.value = true
    }

    const editDescriptionLength = (description) => {
      if(description != '' || description != null) {
        if(description.length >= 20) {
          return description.substring(0, 20)+"..."
        } else {
          return description
        }
      }
    }

    const getLessonGuide = (item) => {
      editLessonGuideId.value = item.guide[0].id
      editLessonGuide.value = item.guide[0].guide
      editLessonGuideTranslation.value = ""
      hasGuideTranslation.value = false
      editGuideTranslation.value = 'no'
      if (item.guide[0].translation) {
        editLessonGuideTranslation.value = item.guide[0].translation.guide
        hasGuideTranslation.value = true
        editGuideTranslation.value = 'yes'
      }
      lessonGuideViewStatus.value = "view"
      editLessonGuideModal.value = true
    }

    const editLessonDetails = (item) => {
      lessonData.value = item
      editLessonId.value = lessonData.value.id
      editLessonName.value = lessonData.value.name
      editLessonDuration.value = lessonData.value.duration
      editLessonDescription.value = lessonData.value.description ? lessonData.value.description : ''
      if (lessonData.value.translation != null) {
        editLessonNameTranslation.value = lessonData.value.translation.name
        editLessonDescriptionTranslation.value = lessonData.value.translation.description ? lessonData.value.translation.description : ''
        editTranslation.value = 'yes'
      }
      editLessonModal.value = true
    }

    const isAddNewLessonSidebarActive = ref(false);

    const {
      fetchLessons,
      storeLesson,
      updateLesson,
      tableColumns,
      per_page,
      current_page,
      totalLessons,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLessonsListTable,
      refetchData,

      addLessonModal,
      addLessonName,
      addLessonDuration,
      addLessonDescription,
      addTranslation,
      addLessonNameTranslation,
      addLessonDescriptionTranslation,

      lessonData,

      editLessonModal,
      editLessonId,
      editLessonName,
      editLessonDuration,
      editLessonDescription,
      editTranslation,
      editLessonNameTranslation,
      editLessonDescriptionTranslation,

      addLessonGuideModal,
      addLessonIdGuide,
      addLessonGuide,
      addGuideTranslation,
      addLessonGuideTranslation,

      editLessonGuideModal,
      editLessonGuideId,
      editLessonGuide,
      editGuideTranslation,
      editLessonGuideTranslation,

      storeLessonGuide,
      updateLessonGuide,
    } = useLessonsList();

    return {
      // Sidebar
      isAddNewLessonSidebarActive,

      maxChar,
      editDescriptionLength,
      editorOption,

      fetchLessons,
      storeLesson,
      updateLesson,
      tableColumns,
      per_page,
      current_page,
      totalLessons,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLessonsListTable,
      refetchData,

      // Add Lesson
      addLessonModal,
      addLessonName,
      addLessonDuration,
      addLessonDescription,
      addTranslation,
      addLessonNameTranslation,
      addLessonDescriptionTranslation,

      lessonData,
      editLessonDetails,

      // Edit Lesson
      editLessonModal,
      editLessonId,
      editLessonName,
      editLessonDuration,
      editLessonDescription,
      editTranslation,
      editLessonNameTranslation,
      editLessonDescriptionTranslation,

      showAddLessonGuideModal,

      addLessonGuideModal,
      addLessonIdGuide,
      addLessonGuide,
      addGuideTranslation,
      addLessonGuideTranslation,

      editLessonGuideModal,
      editLessonGuideId,
      editLessonGuide,
      editGuideTranslation,
      editLessonGuideTranslation,

      lessonGuideViewStatus,

      storeLessonGuide,
      updateLessonGuide,

      getLessonGuide,
      hasGuideTranslation,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
